import routes from './routes.json'
import shared from './shared.json'

export default {
  welcome: 'Welcome!',
  contact: 'Contact',
  about: 'About us',
  routes,
  shared
}
