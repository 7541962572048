import routes from './routes.json'
import shared from './shared.json'
import fat from './fat.json'
import restaurant from './restaurant.json'
import events from './events.json'
import offers from './offers.json'
import maintenance from './maintenance.json'
import campaigns from './campaigns'

export default {
  welcome: 'Välkommen!',
  contact: 'Kontakt',
  about: 'Om oss',
  routes,
  shared,
  fat,
  restaurant,
  events,
  offers,
  maintenance,
  campaigns
}
